
<template>
  <div>
    <el-table
        :data="tableData"
        style="width: 99%;margin: 1% auto"
        row-key="id"
        border
        ref="table"
        :row-style="{height: '38px'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
        :cell-style="cellStyleChange"
        @row-click="clickRow"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="moduleName"
          label="字段名"
          sortable
          header-align="center"
          align="left">
      </el-table-column>
      <el-table-column
          prop="annotation"
          label="注释"
          align="center" >
      </el-table-column>
      <el-table-column
          label="操作"
          align="center">
            <template slot-scope="scope">
          <el-button size="mini"
                     type="primary"
                     @click="updateAnnotation(scope.row)">修改
          </el-button>
          <el-button 
          v-if="isHaveChildrenAnnotation(scope.row)"
                     size="mini"
                     type="primary"
                      @click="openFiledTable(scope.row.id)">详细字段
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 模块注释修改的dialog -->
    <el-dialog
        :title="'模块注解修改('+dialogId+':'+dialogTitle+')'"
        width="25%"
        top="25vh"
        :visible.sync="dialogState"
        :modal-append-to-body="false"
        @close="dialogState = false">
              <el-input
                      type="textarea"
                      :rows="4"
                      :placeholder=textarea
                      v-model="textarea">
              </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogState = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit(dialogId,textarea)" size="small">提 交</el-button>
      </span>
    </el-dialog>
    
<!-- 显示详细字段的dialog -->
     <el-dialog
        :title="'详细字段'"
        width="70%"
        top="10vh"
        :visible.sync="dialogFiledState"
        :modal-append-to-body="false"
        @close="dialogFiledState = false">
         
        <el-table
        :data="tableFieldData"
        style="width: 99%;margin: 1% auto"
        row-key="id"
        border
        ref="table"
        :row-style="{height: '38px'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
        :cell-style="cellStyleChange"
        @row-click="clickRow"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="field"
          label="字段名"
          sortable
          header-align="center"
          align="left">
      </el-table-column>
      <el-table-column
          prop="annotation"
          label="注释"
          align="center" >
      </el-table-column>
      <el-table-column
          label="操作"
          align="center">
            <template slot-scope="scope">
          <el-button size="mini"
                     type="primary"
                     @click="updateAnnotationFromFieldTable(scope.row)">修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFiledState = false" size="small">返回</el-button>
      </span>
    </el-dialog>
  <!-- 字段注解修改的dialog -->
    <el-dialog
        :title="'字段注解修改('+fieldDialogId+':'+fieldDialogTitle+')'"
        width="25%"
        top="25vh"
        :visible.sync="fieldDialogState"
        :modal-append-to-body="false"
        @close="fieldDialogState = false">
              <el-input
                      type="fieldTextarea"
                      :rows="4"
                      :placeholder=fieldTextarea
                      v-model="fieldTextarea"
                      id="fieldInput">
              </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fieldDialogState = false" size="small">取 消</el-button>
        <el-button type="primary" @click="fieldSubmit(fieldDialogId,fieldTextarea,parentid)" size="small">提 交</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>


export default {
  name: "functional-annotation",
  data() {
    return {
      tableData: [],
      dialogState: false,
      dialogTitle: '',
      dialogId: '',
      textarea: '',
      dialogFiledState: false,
      tableFieldData: [],
      fieldDialogState:false,
      fieldDialogTitle: '',
      fieldDialogId: '',
      fieldTextarea: '',
      // parentid用来表示字段所属的moduleId
      parentid: '',
    
    }
  },
  created: function () {
    this.queryModuleView();
  },
  methods: {
    //单元格变色
    cellStyleChange: function (info) {
      let color = '#303133';
      if (info.row.grade === 2) {
        color = '#409EFF';
      } else if (info.row.grade === 3) {
        color = '#5CB87A';
      }else if (info.row.grade === 4) {
        color = '#ff0100';
      }
      return {color: color, padding: '0'};
    },
    //行点击展开
    clickRow: function (row, column, event) {
      if (column.label !== '操作') {
        this.$refs.table.toggleRowExpansion(row);
      }
    },
    //是否有其他子注解
    isHaveChildrenAnnotation: function(children){
      if(children.childrenAnnotation!=""){
        return true;
      }
      else{
        return false;
      }
    },
    //查询注解
    queryModuleView: function () {
      this.$axios({
        method: "get",
        url: "/annotation/queryAnnotationModule",
      }).then(response => {
        this.tableData = response.data.data;
        
      });
    },
    //查询注解根据ModuleId
    queryAnnotationTable: function (id) {
      this.$axios({
        method: "get",
        url: "/annotation/queryByModuleId",
        params :{
           moduleId:id,
        },
      }).then(response => {
        this.tableFieldData = response.data.data;       
      });
    },
     updateAnnotation: function (data) {
      this.dialogState = true
      this.textarea = data.annotation
      this.dialogTitle = data.moduleName
      this.dialogId = data.id
    },

    updateAnnotationFromFieldTable: function (data) {
      this.fieldDialogState = true
      this.fieldTextarea = data.annotation
      this.fieldDialogTitle = data.field
      this.fieldDialogId = data.id
    },
      
    openFiledTable: function(data){
      this.dialogFiledState=true
       this.queryAnnotationTable(data);
      this.parentid=data;
    },
    close() {
      this.dialogState = false
    },
    //修改字段注解
    submit(dialogId,textarea) {      
          this.$axios({
            method: "post",
            url: "/module/updateAnnotation/",
            data: {
              id:dialogId,
              annotation: textarea,
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message({
                message: response.data.msg,
                type: 'success',
                duration: 1000,
              });
              setTimeout(()=>{
                this.queryModuleView()
                this.dialogState = false
              }, 1000)
            } else {
              this.$message.error({
                message: response.data.msg,
                duration: 1000,
              });
            }
          });
    },
    //修改字段注解
        fieldSubmit(dialogId,fieldTextarea,parentid) {     
         this.$axios({
            method: "post",
            url: "/annotation/updateAnnotation/",
            data: {
              id:dialogId,
              annotation: fieldTextarea,
            }
          }).then(response => {
            if (response.data.code === 200) {
              this.$message({
                message: response.data.msg,
                type: 'success',
                duration: 1000,
              });
              setTimeout(()=>{
                this.queryAnnotationTable(parentid);
                this.fieldDialogState = false
              }, 1000)
            } else {
              this.$message.error({
                message: response.data.msg,
                duration: 1000,
              });
            }
          });
    }
  }
}
</script>

<style scoped>

</style>